/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import {checkIsActive} from "../../../../_helpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/dashboard">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="tachometer-alt" />

              {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/> */}
            </span>
              <span className="menu-text"><h4 className="my-auto">Dashboard</h4></span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/admins", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/admins">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="user-alt" />
            </span>
              <span className="menu-text"><h4 className="my-auto">Admin</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/pilots/pilot", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/admin/pilots/pilot">
            <span className="svg-icon menu-icon">
              <i style={{color:"#6A6D6C", fontSize:"22px"}} className="fas fa-user-astronaut"></i>
            </span>
              <span className="menu-text"><h4 className="my-auto">Pilots</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/pilots/studentpilot", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/admin/pilots/studentpilot">
            <span className="svg-icon menu-icon">
              <i style={{color:"#6A6D6C", fontSize:"22px"}} className="fas fa-user-graduate"></i>
            </span>
              <span className="menu-text"><h4 className="my-auto">Student Pilots</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/pilots/nonpilot", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/admin/pilots/nonpilot">
            <span className="svg-icon menu-icon">
              <i style={{color:"#6A6D6C", fontSize:"22px"}} className="fas fa-user-shield"></i>
            </span>
              <span className="menu-text"><h4 className="my-auto">Non Pilots</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/suspendedpilots", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/admin/suspendedpilots">
            <span className="fas-icon menu-icon fas">
                <i style={{color:"#6A6D6C", fontSize:"22px"}} className="fas fa-user-lock"></i>
            </span>
              <span className="menu-text"><h4 className="my-auto">Suspended Pilots</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/flyingclubs", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/admin/flyingclubs">
            <span className="fas-icon menu-icon fas">
                <i style={{color:"#6A6D6C", fontSize:"22px"}} className="fas fa-plane"></i>
            </span>
              <span className="menu-text"><h4 className="my-auto">Flying Clubs</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/businesslistings", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/admin/businesslistings">
            <span className="fas-icon menu-icon fas">
                <i style={{color:"#6A6D6C", fontSize:"22px"}} className="fas fa-briefcase"></i>
            </span>
              <span className="menu-text"><h4 className="my-auto">Business Listings</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/rentallistings", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/admin/rentallistings">
            <span className="fas-icon menu-icon fas">
                <i style={{color:"#6A6D6C", fontSize:"22px"}} className="fas fa-bullhorn"></i>
            </span>
              <span className="menu-text"><h4 className="my-auto">Rental Listings</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/coownerships", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/admin/coownerships">
            <span className="fas-icon menu-icon fas">
                <i style={{color:"#6A6D6C", fontSize:"22px"}} className="fas fa-handshake"></i>
            </span>
              <span className="menu-text"><h4 className="my-auto">Co-Ownership Listings</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/reported", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/admin/reported">
            <span className="fas-icon menu-icon fas">
                <i style={{color:"#6A6D6C", fontSize:"22px"}} className="fas fa-flag"></i>
            </span>
              <span className="menu-text"><h4 className="my-auto">Reported Listings</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/posts", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/admin/posts">
            <span className="fas-icon menu-icon fas">
                <i style={{color:"#6A6D6C", fontSize:"22px"}} className="fas fa-list-alt"></i>
            </span>
              <span className="menu-text"><h4 className="my-auto">Posts</h4></span>
            </NavLink>
          </li>

        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
